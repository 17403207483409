.index_portfolio_wrap {
  background-color: #fa5869;
  transition: all ease 1s;
}

.index_portfolio_grid {
  width: 100%;
  height: 100vh;
  transition: all ease 2s;
}

.current_window_size {
  position: fixed;
  color: #000;
  z-index: 10;
  left: 0;
  bottom: 0;
}
