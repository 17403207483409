.slider_row {
  padding-top: clamp(5px, 7%, 10px);
}

.slide_column {
  padding: 1rem 1rem;
}

.slider_img {
  max-height: 118px;
}

@media (min-width: 424px) {
  .slider_img {
    max-height: 137px;
  }
  .slider_row {
    padding-top: clamp(5px, 7%, 15px);
  }
}

@media (min-width: 576px) {
  .slider_img {
    max-height: 149px;
  }
  .slider_row {
    padding-top: clamp(5px, 8vw, 12px);
  }
}

@media (min-width: 768px) {
  .slider_row {
    padding-top: clamp(7px, 5%, 16px);
  }
}

@media (min-width: 840px) {
  .slider_img {
    max-height: 160px;
  }
}

@media (min-width: 992px) {
  .slider_img {
    max-height: 186px;
  }
}

@media (min-width: 1024px) {
  .slider_img {
    max-height: 200px;
  }
}

@media (min-width: 1200px) {
  .slider_img {
    max-height: 210px;
  }
}
