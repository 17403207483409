@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.Menubar_wrap {
  background-color: rgba(255, 255, 255, 0.65);
  position: fixed;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.Menubar_logo_wrap {
  align-items: center;
  justify-content: center;
}

.Menubar_list {
  padding: 3rem 2rem 3rem 1rem;
}

.Menubar_list_item {
  /* font-size: 21px; */
}

.Menubar_list_item a,
.Menubar_list_item button {
  text-decoration: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
}

/* index version */
.Menubar_wrap_index {
  left: 0;
  top: 2rem;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 15px 0px 28px;
  display: flex;
  padding: 0 0 2rem 0;
}

.Menubar_logo_index {
  width: max(25%, 170px);
  padding: 0;
}
.Menubar_wrap_index .Menubar_list {
  padding: 3rem 2rem 0 1rem;
}

.Menubar_wrap_index .Menubar_list_item:hover::after {
  content: "";
  position: absolute;
  width: 3px;
  height: calc(100% - 14px);
  right: -1rem;
  background: #fa5869;
  top: 7px;
}

.Menubar_wrap_index .Menubar_list_item {
  padding: 0.5rem 0;
}

.Menubar_wrap_index .Menubar_list_item a {
  color: #194b92;
}

.Menubar_wrap.Menubar_wrap_index .Footer_text {
  padding: 2rem 0 0 0;
  bottom: 0;
}

/* about version */
.Menubar_wrap_about {
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: center;
}

.Menubar_wrap_about .Menubar_logo {
}

.Menubar_list_wrap_about {
  padding: 15px 2rem 0 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.Menubar_list_wrap_about .Menubar_list_item:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0%;
  background: #fa5869;
  top: calc(50% + 1.5px);
  z-index: -1;
}

.Menubar_list_wrap_about .Menubar_list_item:last-of-type:hover::after {
  /* width: 102%; */
  /* left: 7%; */
}

.Menubar_list_wrap_about .Menubar_list_item a {
  padding: 0 10px;
  color: #222;
}

.Menubar_list_wrap_about .Menubar_list_item:last-of-type a {
  padding: 0 0 0 10px;
}

.Menubar_wrap_about .Menubar_list_logo {
  width: 100%;
  max-width: 310px;
}

.Menubar_wrap_about .Menubar_list {
  padding: 0rem 0.5rem 2rem 1rem;
}

.Menubar_list {
  z-index: 3;
}

.Menubar_wrap_about_mobile {
  position: fixed;
  max-width: 100vw;
  top: 0;
  width: 100%;
  display: flex;
  padding: 15px 0 10px 0;
  justify-content: space-evenly;
}

.Menubar_wrap_about_mobile .Menubar_logo_wrap {
  /* padding: .8rem .8rem 0 0 ; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.Menubar_wrap_about_mobile .Menubar_list_logo {
  /* max-width: calc(100% - 160px); */
  max-width: 100%;
  display: inline-block;
}

.Menubar_wrap_about_mobile .Menubar_list {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 0 2rem;
}

.Menubar_wrap_about_mobile .Menubar_logo_about {
  max-width: 200px;
  display: inline-block;
}

.Menubar_wrap_about_mobile .Menubar_list_wrap_about {
  padding: 0;
}

/* work version */
.Menubar_wrap.Menubar_wrap_work {
  background-color: #194b92;
}

.Menubar_wrap.Menubar_side_wrap {
  position: fixed;
  /* height: 6.4rem; */
  width: 100%;
  backdrop-filter: none;
  /* align-items: flex-end; */
  top: 0;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* padding: 30px 0 8px 0; */
  padding: 15px 0 10px 0;
  flex-direction: row;
  justify-content: center;
}

.Menubar_side_wrap .Menubar_logo_wrap {
  margin-top: 0;
  width: 200px;
  left: 0;
  top: 12px;
  position: relative;
  order: 1;
}

.Menubar_list_wrap_work,
.Menubar_list_wrap_single {
  /* justify-content: space-between; */
  /* flex-direction: row; */
  /* width: 100%; */
  order: 2;
  width: calc(100% - 200px);
}

.Menubar_wrap_single,
.Menubar_wrap_work h1 {
  font-size: clamp(1.8rem, 22vw, 2rem);
  margin: 0;
  padding: 10px 0 0 0;
  /* width: calc(100% - 220px); */
  /* max-width: 200px; */
}

.Menubar_list_wrap_work li {
  padding: 0 1rem 0 0;
}

.Menubar_list_wrap_work .Menubar_list_item a {
  color: #fff;
}

.Menubar_list_wrap_single .Menubar_list_item:hover::after,
.Menubar_list_wrap_work .Menubar_list_item:hover::after {
  content: "";
  position: absolute;
  /* height: 3px; */
  /* right: 0; */
  width: 110%;
  /* left: -5%; */
  background: #fa5869;
  top: calc(50% + 1.5px);
  z-index: -1;
  height: 4px;
  top: calc(50% + 0px);
  left: unset;
  right: 0;
}

.Menubar_list_wrap_work .Menubar_list,
.Menubar_list_wrap_single .Menubar_list {
  padding: 0;
  flex-direction: column;
  margin: 0;
  z-index: 3;
}

.Menubar_wrap.Menubar_wrap_work .Footer_text {
  display: none;
}

.Menubar_filter_wrap {
  width: clamp(156px, 96%, 440px);
  /* width: 96%; */
  justify-content: space-between;
  padding: 16px 0 0 0;
  flex-wrap: wrap;
  order: 3;
}

.Menubar_filter_button {
  width: 23%;
  border: solid 1px #fa5869;
  border-radius: 60px;
  background-color: transparent;
  color: #fa5869;
  padding: 2px 0;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Menubar_filter_button.active {
  animation: scaleUp 2s ease;
  background-color: #fa5869;
  color: #fff;
}

/* single version */
.Menubar_wrap.Menubar_wrap_single {
  background-color: #fff;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  height: unset;
}
.Menubar_wrap.Menubar_wrap_single h1 {
  color: #fa5869;
}

.Menubar_wrap_single .Menubar_logo_wrap {
  order: 0;
}
.Menubar_side_wrap h1 {
  max-width: unset;
  text-align: right;
}

.Menubar_list_wrap_single {
  order: 1;
  display: block;
  /* padding: 0 1rem 0 0; */
  width: calc(100% - 200px);
}

.Menubar_wrap_single .single_info {
  order: 2;
  width: 100%;
}

.Menubar_list_wrap_single li {
  padding: 0 1rem 0 0;
}

.Menubar_list_wrap_single li a {
  color: #222;
}

.Menubar_wrap_single .single_subtitle {
  color: #194b92;
  text-align: left;
  position: relative;
  left: 1rem;
  margin: 2rem 0 0.8rem 0;
  font-size: clamp(1rem, 16vw, 1.4rem);
}
.single_category_wrap {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 0 0 1rem;
}

.Menubar_wrap_single .single_category_text {
  color: #5d5a5a;
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.7rem;
}
.Menubar_wrap_single .single_category_pill {
  color: #fa5869;
  border: 2px solid #fa5869;
  display: inline-block;
  padding: 0 7px 2px 7px;
  right: 0;
  border-radius: 100px;
  margin: 0;
  font-size: 12px;
  margin-left: 7px;
}
/* max-width => 小於多少則... */

@media (min-width: 425px) {
  /* about version */
  .Menubar_wrap_about_mobile .Menubar_logo_about {
    max-width: 180px;
  }
  .Menubar_wrap_about_mobile .Menubar_list_logo {
    /* width: calc(100% - 180px); */
    /* max-width: 330px; */
  }
  .Menubar_wrap_about .Menubar_logo_about {
    max-width: 240px;
  }
}

@media (min-width: 576px) {
  .Menubar_wrap_index {
    width: max(21%, 270px);
    left: 8%;
    height: 100%;
    display: block;
    top: 0;
  }
  .Menubar_wrap_index .Menubar_list {
    padding: 3rem 2rem 3rem 1rem;
  }
  .Menubar_logo_index {
    width: max(25%, 200px);
    padding: 3rem 0;
  }
  .Menubar_wrap.Menubar_wrap_index .Footer_text {
    padding: 0;
    bottom: 2.6rem;
    left: 0;
  }
  .Menubar_list_item a,
  .Menubar_list_item button {
    font-size: 16px;
  }
  .Menubar_list_wrap_work .Menubar_list_item:hover::after {
    /* width: 3px; */
    /* height: 100%; */
    /* right: -1rem; */
    /* top: 0; */
    /* left: unset; */
  }
  .Menubar_list_wrap_about .Menubar_list_item:hover::after {
    height: 8px;
    top: calc(50% - 4px);
  }
  .Menubar_wrap_single .single_category_pill {
    margin-left: 12px;
  }
}

/* over tablet */
@media (min-width: 768px) {
  .Menubar_list_item a,
  .Menubar_list_item button {
    font-size: 16px;
    display: block;
  }
  .Menubar_wrap_index {
    width: max(21%, 320px);
    left: 8%;
    height: 100%;
    display: block;
    top: 0;
  }
  /* work version */
  .Menubar_wrap.Menubar_side_wrap {
    height: 100%;
    width: 25%;
    align-items: flex-end;
    align-items: end;
    top: 0;
    flex-direction: column;
    flex-wrap: unset;
    padding: 0;
  }
  .Menubar_side_wrap .Menubar_logo_wrap {
    margin-top: 4rem;
    width: 100%;
    max-width: unset;
  }
  .Menubar_side_wrap h1 {
    margin: 2rem auto;
    /* font-size: 3rem; */
    /* width: 100%; */
  }
  .Menubar_list_wrap_work,
  .Menubar_list_wrap_single {
    height: 100%;
    flex-direction: column;
    width: 100%;
    order: 3;
  }
  .Menubar_list_wrap_work .Menubar_list,
  .Menubar_list_wrap_single .Menubar_list {
    padding: 3rem 0 6rem 0;
    width: auto;
    flex-direction: column;
  }
  /* .Menubar_wrap_work .Menubar_list_item {
    margin: 0.5rem 0;
  } */
  .Menubar_list_wrap_work li {
    padding: 0;
  }
  .Menubar_list_wrap_work .Menubar_list_item,
  .Menubar_list_wrap_single .Menubar_list_item {
    padding: 0.3rem 0;
  }
  .Menubar_list_wrap_single .Menubar_list_item:hover::after,
  .Menubar_list_wrap_work .Menubar_list_item:hover::after {
    width: 3px;
    height: calc(100% - 14px);
    right: -1rem;
    top: 7px;
    left: unset;
  }
  /* .Menubar_list_wrap_work .Menubar_list_item a {
    font-size: 20px;
  } */
  .Menubar_wrap.Menubar_side_wrap .Footer_text {
    display: block;
    /* font-size: 12px; */
    text-align: center;
    width: 200px;
    left: calc(50% - 100px);
  }
  .Menubar_wrap_about .Menubar_logo_wrap {
    /* width: 220px; */
  }
  .Menubar_filter_wrap {
    justify-content: end;
    width: clamp(156px, 86%, 176px);
    padding: 10px 0;
    right: 0;
  }
  .Menubar_filter_button {
    width: 48%;
    margin: 7px 0 0 0;
  }
  .Menubar_filter_button:nth-of-type(even) {
    margin-left: 7px;
  }
  /* single page over tablet version */
  .Menubar_wrap.Menubar_side_wrap.Menubar_wrap_single {
    height: 100%;
    /* width: 25%; */
    /* align-items: flex-end;
    align-items: end; */
    top: 0;
    flex-direction: column;
    flex-wrap: unset;
    padding: 0;
  }
  .Menubar_list_wrap_single {
    order: 2;
  }

  .Menubar_wrap_single .single_info {
    order: 1;
    /* width: auto; */
  }
  .Menubar_wrap_single .single_subtitle {
    text-align: right;
    /* font-size: 1.8rem; */
    padding: 1rem 0 1.2rem 0;
    left: 0;
  }
  .Menubar_wrap_single .single_category_text {
    text-align: right;
    margin: 1rem 0;
    font-size: 1rem;
  }
  .single_category_wrap {
    display: block;
  }
  .Menubar_wrap_single .single_category_pill {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .Menubar_side_wrap h1 {
    padding-right: 0;
    margin: 1.2rem 0;
    text-align: right;
    max-width: unset;
    font-size: 2rem;
  }
  /* .Menubar_list_wrap_work li a {
    font-size: 22px;
  } */
  .Menubar_side_wrap .single_category_text {
    font-size: 1rem;
  }
  .Menubar_wrap_about {
    justify-content: space-between;
  }
  .Menubar_wrap_single .single_subtitle {
    font-size: clamp(1.2rem, 8vw, 1.6rem);
  }
}

@media (min-width: 1080px) {
  .Menubar_wrap.Menubar_side_wrap {
    width: 22%;
  }
}

@media (min-width: 1280px) {
  .Menubar_wrap_about .Menubar_logo_about {
    max-width: 320px;
  }
  .Menubar_list_wrap_work .Menubar_list {
    padding: 3rem 0 3rem 1rem;
  }
  .Menubar_wrap_single .single_category_text {
    font-size: 1.3rem;
  }
}

@media (min-width: 1600px) {
  .Menubar_logo_index {
    width: max(25%, 280px);
  }
  .Menubar_side_wrap h1 {
    /* margin: 2rem ; */
    font-size: 3.2rem;
    /* padding-right: 2rem; */
  }
  .Menubar_side_wrap .single_subtitle {
    font-size: 2rem;
    padding: 1rem 0 1rem 0;
    line-height: 1.3;
  }
  .Menubar_side_wrap .Menubar_list_item {
    /* ,
  .Menubar_list_wrap_single .Menubar_list_item */
    padding: 0.5rem 0;
  }
}
