@font-face {
  font-family: "HunInn";
  src: url(https://cdn.jsdelivr.net/gh/marsnow/open-huninn-font@1.1/font/jf-openhuninn.eot); /* IE9 Compat Modes */
  src: url(https://cdn.jsdelivr.net/gh/marsnow/open-huninn-font@1.1/font/jf-openhuninn.eot?#iefix)
      format("embedded-opentype"),
    /* IE6-IE8 */
      url(https://cdn.jsdelivr.net/gh/marsnow/open-huninn-font@1.1/font/jf-openhuninn.woff)
      format("woff"),
    /* Modern Browsers */
      url(https://cdn.jsdelivr.net/gh/marsnow/open-huninn-font@1.1/font/jf-openhuninn.ttf)
      format("truetype"),
    /* Safari, Android, iOS */
      url(https://cdn.jsdelivr.net/gh/marsnow/open-huninn-font@1.1/font/jf-openhuninn.svg#SealmemoryHeader)
      format("svg"); /* Legacy iOS */
}

.font_weight_bold {
  font-weight: bold;
}

.font_huninn {
  font-family: "HunInn", Arial, Helvetica, sans-serif;
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.p_0 {
  padding: 0 !important;
}

/* display */
.d_flex {
  display: flex;
}

.d_block {
  display: block;
}

.full_width {
  width: 100%;
}

/* flex */
.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_left {
  justify-content: left;
}

.flex_column {
  flex-direction: column;
}

.list_style_none {
  list-style: none;
}

.flex_wrap {
  flex-wrap: wrap;
}

.align_items_end {
  align-items: flex-end;
}

/* position */
.position_absolute {
  position: absolute;
}

.position_relative {
  position: relative;
}

.position_fixed {
  position: fixed;
}

/* margin */
.mx_auto {
  margin: auto;
}

.overflow_hidden {
  overflow: hidden;
}

/* rwd breakpoint */
/* X-small devices */
@media (min-width: 360px) {
}

/* Small devices (576px and up)*/
@media (min-width: 576px) {
  .flex_sm_cloumn {
    flex-direction: column;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .flex_md_cloumn {
    flex-direction: column;
  }
  .flex_md_row {
    flex-direction: row;
  }
  .justify_content_left_md {
    justify-content: left;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* lg */
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
