.scroll_top {
  right: 1rem;
  border: 2px solid #faa5b9;
  border-radius: 200px;
  bottom: 1rem;
  width: 44px;
  height: 44px;
}

.scroll_top img {
  padding: 5px;
}

.single_content_wrap {
  padding: 15rem 15px 0 15px;
  max-width: 100%;
  left: 0;
}

.single_content_wrap img {
  max-width: 100%;
}

.single_content_wrap p {
  color: #ffedaf;
  font-size: 4rem;
  right: 2%;
  top: 0;
  margin: 0;
  z-index: -1;
  text-shadow: 0 0 9px #ffedaf;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

@media (min-width: 768px) {
  .single_content_wrap p {
    font-size: 5rem;
  }
  .single_content_wrap {
    padding: 3rem 0 3rem 0;
    max-width: calc(100% - 28%);
    left: 27.8%;
  }
  .single_content_wrap img {
    /* max-width: 88%; */
    width: clamp(340px, 74%, 600px);
  }
}

@media (min-width: 992px) {
  .single_content_wrap p {
    top: 1rem;
  }
}

@media (min-width: 1080px) {
  .single_content_wrap {
    max-width: calc(100% - 25%);
    left: 24.8%;
  }
}

@media (min-width: 1440px) {
  .single_content_wrap img {
    /* max-width: 960px; */
    width: clamp(620px, 72%, 800px);
    /* padding-left: 2rem; */
  }
}

@media (min-width: 1600px) {
  .single_content_wrap p {
    font-size: 6.3rem;
  }
  .single_content_wrap img {
    /* max-width: 1080px; */
    /* padding-left: 4rem; */
  }
}
