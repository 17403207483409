.Works_wrap {
  background-color: #194b92;
  width: 100%;
  min-height: 100vh;
}

.Works_album_wrap {
  padding: 9rem 15px 0 15px;
  max-width: 100%;
  left: 0;
}

.grid-outer {
  /* max-width: 1000px; */
}

.grid-1 img,
.grid-2 img,
.grid-3 img,
.grid-4 img,
.grid-5 img,
.grid-6 img,
.grid-7 img,
.grid-8 img,
.grid-9 img,
.grid-10 img,
.grid-11 img,
.grid-12 img {
  max-width: 100%;
  display: block;
}

.work-item {
  overflow: hidden;
  position: relative;
  display: block;
}

.work-item .work-item-overlap {
  background: rgba(0, 0, 0, 0.6);
  transform: translateY(-100%);
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.8s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.work-item:hover .work-item-overlap {
  opacity: 100;
}

.work-item-overlap p {
  font-weight: bold;
  font-size: clamp(12px, 0.75rem, 16px);
}

.grid-row {
  max-width: 1380px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /*define 3 columns with equal width*/
  grid-template-rows: repeat(8, auto);
  align-items: stretch;
  gap: 10px;
  padding-bottom: 12px;
}
/* for mobile */
.grid-1 {
  grid-area: 1 / 1 / 2 / 2;
}
.grid-2 {
  grid-area: 2 / 1 / 3 / 2;
}
.grid-3 {
  grid-area: 1 / 2 / 3 / 4;
}

.grid-4 {
  grid-area: 3 / 1 / 5 / 3;
}

.grid-5 {
  grid-area: 3 / 3 / 4 / 4;
}
.grid-6 {
  grid-area: 4 / 3 / 5 / 4;
}

.grid-7 {
  grid-area: 5 / 1 / 6 / 2;
}

.grid-8 {
  grid-area: 6 / 1 / 7 / 2;
}

.grid-9 {
  grid-area: 5 / 2 / 7 / 4;
}

.grid-10 {
  grid-area: 7 / 1 / 9 / 3;
}

.grid-11 {
  grid-area: 7 / 3 / 8 / 4;
}

.grid-12 {
  grid-area: 8 / 3 / 9 / 4;
}
/* for tablet 大於768時 */
@media (min-width: 768px) {
  .Works_album_wrap {
    padding: 15px 0 15px 0;
    max-width: calc(100% - 28%);
    left: 27.8%;
  }
  .grid-row {
    grid-template-columns: repeat(4, 1fr); /*define 3 columns with equal width*/
    grid-template-rows: repeat(6, auto);
    align-items: stretch;
    gap: 10px;
  }
  .grid-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .grid-3 {
    grid-area: 1 / 2 / 3 / 4;
  }
  .grid-4 {
    grid-area: 1 / 4 / 2 / 5;
  }
  .grid-5 {
    grid-area: 2 / 4 / 3 / 5;
  }
  .grid-6 {
    grid-area: 3 / 1 / 5 / 3;
  }
  .grid-7 {
    grid-area: 3 / 3 / 4 / 4;
  }
  .grid-8 {
    grid-area: 4 / 3 / 5 / 4;
  }
  .grid-9 {
    grid-area: 3 / 4 / 4 / 5;
  }
  .grid-10 {
    grid-area: 4 / 4 / 5 / 5;
  }
  .grid-11 {
    grid-area: 5 / 1 / 7 / 3;
  }
  .grid-12 {
    grid-area: 5 / 3 / 7 / 5;
  }
  .work-item-overlap p {
    font-weight: bold;
    font-size: clamp(12px, 1.4rem, 16px);
  }
}

/* screen over 1280 */
@media (min-width: 960px) {
  .grid-row {
    grid-template-columns: repeat(6, 1fr); /*define 3 columns with equal width*/
    grid-template-rows: repeat(4, auto);
    align-items: stretch;
  }
  .grid-row:nth-of-type(n + 1) {
    /* padding-top: 15px; */
  }
  .grid-1 {
    margin: 0;
    padding: 0;
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-2 {
    margin: 0;
    padding: 0;
    grid-area: 2 / 1 / 3 / 2;
  }
  .grid-3 {
    margin: 0;
    padding: 0;
    grid-area: 1 / 2 / 3 / 4;
  }

  .grid-4 {
    grid-area: 1 / 4 / 3 / 6;
  }

  .grid-5 {
    grid-area: 1 / 6 / 2 / 7;
  }
  .grid-6 {
    grid-area: 2 / 6 / 3 / 7;
  }

  .grid-7 {
    grid-area: 3 / 1 / 5 / 3;
  }

  .grid-8 {
    grid-area: 3 / 3 / 4 / 4;
  }

  .grid-9 {
    grid-area: 4 / 3 / 5 / 4;
  }

  .grid-10 {
    grid-area: 3 / 4 / 4 / 5;
  }

  .grid-11 {
    grid-area: 4 / 4 / 5 / 5;
  }

  .grid-12 {
    grid-area: 3 / 5 / 5 / 7;
  }
}

@media (min-width: 1080px) {
  .Works_album_wrap {
    max-width: calc(100% - 25%);
    left: 24.8%;
  }
}
