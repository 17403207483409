.Footer_text {
  color: #777;
  letter-spacing: 1px;
  bottom: 1rem;
  font-size: 12px;
  width: 100%;
}

.Footer_text_index {
  /* display: none; */
}

.Footer_text_about {
  right: 2rem;
  bottom: 12px;
  margin: 0;
  /* font-size: 12px; */
}

@media (min-width: 768px) {
  .Footer_text_index {
    /* left: 2rem; */
    display: block;
  }
  /* .Footer_text_about {
    font-size: 12px;
  } */
}

@media (min-width: 992px) {
  .Menubar_wrap_about .Footer_text_about {
    width: clamp(180px, 12%, 200px);
    right: 2rem;
    position: absolute;
  }
  .Footer_text_about {
    /* right: 2.6rem; */
  }
}

@media (min-width: 1600px) {
  /* .Footer_text {
    font-size: 12px;
  } */
}
