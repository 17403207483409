img {
  max-width: 100%;
}

.sustainability_sec {
  padding: 2.5rem 4% 4rem 4%;
}

.sustainability_sec:last-of-type {
  background-size: contain;
}

.sustainability_wrap {
  max-width: 1200px;
}

.sustainability_sec .sustainability_col {
  width: 100%;
  margin: 2rem auto;
  width: clamp(200px, 88%, 600px);
}

/* .sustainability_sec .sustainability_col:nth-of-type(n + 3) {
  margin: 6rem 0;
} */

.sustainability_sec .sustainability_col li {
  padding: 0.5rem;
  margin: 1rem 0;
  display: block;
  width: clamp(200px, 90%, 360px);
  /* clamp(61%, 95%, 360px) */
  border-radius: 0 200px 200px 0;
}

.sustainability_sec .sustainability_col li:first-child {
  margin: 0 0 1rem 0;
}

.sustainability_sub_wrap {
  padding: 0 0 0 1rem;
}

.sustainability_sub_wrap ul {
  padding: 0;
  margin: 0 0 auto 0;
}

.sustainability_sec .sustainability_col:nth-of-type(1) li {
  background-color: #dae7f6;
}

.sustainability_sec .sustainability_col:nth-of-type(2) li {
  background-color: #e8f1db;
}

.sustainability_sec .sustainability_col:nth-of-type(3) li {
  background-color: #fcfacd;
}

.sustainability_sec .sustainability_col:nth-of-type(4) li {
  background-color: #cee5df;
}

.sustainability_sub_icon {
  width: 46px;
}

.sustainability_sub_icon:nth-of-type(n + 2) {
  margin-left: 1rem;
}

.sustainability_sec p {
  line-height: 1.7em;
}

.sustainability_sec:nth-of-type(2) {
  background: #5db59b;
  margin-top: 126px;
  background-position: 50% 100%;
  background-size: clamp(100px, 16vw, 120px);
  padding-bottom: 150px;
}

.sustainability_sec:nth-of-type(3) {
  background: #b7d984;
}

.sustainability_sec:nth-of-type(3) h1 {
  font-weight: 300;
}

.sustainability_sec:nth-of-type(2) h1 {
  color: #0866ff;
}

.sustainability_icon_wrap img {
  /* width: clamp(86px, 100%, 110px); */
}

@media (min-width: 768px) {
  .sustainability_sec {
    padding: 3rem 6% 4rem 6%;
  }
  .sustainability_sec:nth-of-type(2) {
    background: #5db59b;
    margin-top: 126px;
    background-position: 88% 100%;
    background-size: clamp(100px, 16vw, 120px);
    padding-bottom: 3rem;
  }

  .sustainability_sec .sustainability_col {
    width: 50%;
    margin: 3rem auto;
  }
}

@media (min-width: 992px) {
  .sustainability_sec {
    padding: 3rem 12% 6.5rem 12%;
  }
  .sustainability_sec:nth-of-type(2) {
    background-position: 77% 100%;
    /* background-size: clamp(160px, 16vw, 200px); */
  }
  .sustainability_sec .sustainability_col {
    margin: 3rem 0;
  }
  .sustainability_sub_wrap {
    padding: 0 0 0 3rem;
  }
}

@media (min-width: 1280px) {
  .sustainability_sub_icon {
    width: 60px;
  }
}

@media (min-width: 1400px) {
  .sustainability_sec:nth-of-type(2) {
    margin-top: 155px;
    background-position: 70% 100%;
    background-size: clamp(120px, 16vw, 150px);
  }

  .sustainability_sec .sustainability_col {
    margin: 4rem 0;
  }
}

@media (min-width: 1600px) {
  .sustainability_sec:nth-of-type(2) {
    background-position: 78% 100%;
  }
  .sustainability_sub_wrap {
    padding: 0 0 0 5rem;
  }
  .sustainability_sec .sustainability_col {
    margin: 6rem 0;
  }
}
